/* eslint-disable react/prop-types */
import React from 'react'
import { Phone, Mail } from 'react-feather'
import { graphql } from 'gatsby'
import { SimpleGrid } from '@mantine/core'

import PageHeader from '../components/PageHeader'
import FormSimpleAjaxBooking from '../components/FormSimpleAjaxBooking'
import Content from '../components/Content'
import Layout from '../components/Layout'
import '../components/ContactPage.css'

// Export Template for use in CMS preview
const BookingPageTemplate = ({ data }) => {
  const { body, subTitle, featuredImage, title } = data.directus.bookingPage
  const { phone, email } = data.directus.contactPage
  const { skips } = data.directus

  const prices = skips.map((i) => (
    <div style={{ fontSize: '0.9em', marginBottom: 12 }}>
      <div style={{ color: '#f1c40f', fontSize: '1.5em' }}>{i.title}</div>

      <div>Hard Core Waste: {i.hardCorePrice}</div>
      <div>Soil: {i.soilPrice}</div>
      <div>Mixed Waste: {i.mixedWastePrice}</div>
    </div>
  ))
  return (
    <main className="Contact">
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content body={body} />
            <h2>Pricing</h2>
            <SimpleGrid
              spacing="lg"
              breakpoints={[
                { maxWidth: 980, cols: 2, spacing: 'md' },
                { maxWidth: 755, cols: 1, spacing: 'sm' },
                { maxWidth: 600, cols: 1, spacing: 'sm' }
              ]}
              cols={2}
            >
              {prices}
            </SimpleGrid>
            <div className="Contact--Details">
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Phone /> {phone}
                </a>
              )}
              {/* {mobile && (
                <a className="Contact--Details--Item" href={`tel:${mobile}`}>
                  <Smartphone /> {mobile}
                </a>
              )} */}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <FormSimpleAjaxBooking skips={skips} name="Booking Form" />
          </div>
        </div>
      </section>
    </main>
  )
}

const BookingPage = ({ data }) => (
  <Layout meta={false}>
    <BookingPageTemplate data={data} />
  </Layout>
)

export default BookingPage

export const pageQuery = graphql`
  query bookingPage {
    directus {
      bookingPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
      }
      contactPage {
        phone
        mobile
        email
      }
      skips {
        title
        hardCorePrice
        soilPrice
        mixedWastePrice
      }
    }
  }
`
