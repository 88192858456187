/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
// import Helmet from 'react-helmet'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import { showNotification } from '@mantine/notifications'
import { DatePicker } from '@mantine/dates'
import { Input, Textarea, Select, Button } from '@mantine/core'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'Simple Form Ajax',
    skips: [],
    subject: '', // optional subject of the notification email
    action: '',
    successMessage:
      'Thanks for your booking enquiry, we will get back to you as soon as possible',
    errorMessage:
      'There is a problem, your message has not been sent, please try contacting us via email'
  }

  state = {
    alert: '',
    disabled: false,
    keyIndex: 0
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(`${form.action}?${stringify(data)}`, {
      method: 'POST'
    })
      .then((res) => {
        if (res.ok) {
          return res
        }
        throw new Error('Network error')
      })
      .then(() => {
        const p = this.state.keyIndex
        this.setState({ keyIndex: p + 1 })

        form.reset()
        showNotification({
          position: 'top-center',
          autoClose: 8000,
          title: 'Success',
          message: 'Your Booking Enquiry was sent'
        })
        this.setState({
          alert: this.props.successMessage,
          disabled: false
        })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        showNotification({
          position: 'top-center',
          autoClose: 8000,
          title: 'Error',
          message: 'Your Booking Enquiry was not sent - please try again.'
        })
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action, skips } = this.props
    const selOptions = []
    skips.forEach((i) => {
      selOptions.push({
        value: `${i.title}_Hard_Core_Waste_${i.hardCorePrice}`,
        label: `${i.title} - Hard Core Waste - ${i.hardCorePrice}`
      })
      selOptions.push({
        value: `${i.title}_Soil_${i.soilPrice}`,
        label: `${i.title} - Soil - ${i.soilPrice}`
      })
      selOptions.push({
        value: `${i.title}_Mixed_Waste_${i.mixedWastePrice}`,
        label: `${i.title} - Mixed Waste - ${i.mixedWastePrice}`
      })
    })
    return (
      <form
        className="Form"
        name={name}
        action={action}
        onSubmit={this.handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        {this.state.alert && (
          <div className="Form--Alert">{this.state.alert}</div>
        )}
        <div className="Form--Group">
          <p className="ohnohoney">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <Input.Wrapper style={{ width: '48%' }} label="First Name" required>
            <Input required name="firstname" placeholder="First Name" />
          </Input.Wrapper>
          <Input.Wrapper style={{ width: '48%' }} label="Last Name" required>
            <Input required name="lastname" placeholder="Last Name" />
          </Input.Wrapper>
        </div>
        <label className="Form--Label">
          <Textarea
            required
            fullWidth
            name="address"
            style={{ width: '100%' }}
            withAsterix
            placeholder="Your Address"
            label="Your Address"
            autosize
            minRows={5}
          />
        </label>
        <label className="Form--Label">
          <Input.Wrapper
            style={{ width: '100%' }}
            label="Email Address"
            required
          >
            <Input name="email" placeholder="Email Address" />
          </Input.Wrapper>
        </label>
        <label className="Form--Label">
          <label className="Form--Label">
            <Input.Wrapper style={{ width: '100%' }} label="Telephone" required>
              <Input required name="phone" placeholder="Telephone" />
            </Input.Wrapper>
          </label>
        </label>
        <label className="Form--Label">
          <Select
            style={{ width: '100%' }}
            withAsterisk
            withinPortal
            required
            key={`sel_${this.state.keyIndex}}`}
            name="required_skip"
            label="Skip Type Required"
            placeholder="Choose Skip"
            data={selOptions}
            zIndex={10000}
          />
        </label>
        <label className="Form--Label">
          <DatePicker
            key={`dp_${this.state.keyIndex}}`}
            style={{ width: '100%' }}
            fullWidth
            required
            withinPortal
            placeholder="Pick date"
            label="Date Required"
            name="date-required"
            withAsterix
          />
        </label>
        <label className="Form--Label">
          <Textarea
            fullWidth
            name="optional_message"
            style={{ width: '100%' }}
            placeholder="Optional Message"
            label="Optional Message"
            autosize
            minRows={10}
          />
        </label>
        {!!subject && <input type="hidden" name="subject" value={subject} />}
        <input type="hidden" name="form-name" value={name} />
        <Button
          disabled={this.state.disabled}
          value="Submit Booking Enquiry"
          type="submit"
        >
          SUBMIT BOOKING ENQUIRY
        </Button>
      </form>
    )
  }
}

export default Form
